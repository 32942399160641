.wrapper {
  > div {
    margin-top: var(--c-space-40);

    @media (--v-from-md) {
      margin-top: var(--c-space-56);

      > div {
        height: 370px;

        & video {
          height: 100%;
          object-fit: cover;
        }
      }
    }

    > div {
      & button {
        border-radius: 50%;
        opacity: 0;
        transition: 0.15s;
        -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
        &:focus-within {
          opacity: 0.75;
        }
      }
      &:hover {
        & button {
          opacity: 0.75;
        }
      }
    }
  }
}
